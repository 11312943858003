<template>
  <div class="card" style="margin-top:0;">
    <div class="card-header card-header-icon card-header-rose">
      <div class="card-icon">
        <i class="material-icons">notes</i>
      </div>
      <h3 class="card-title">PARTES DE VIAJE</h3>
    </div>
    <div class="card-body">
      <v-row class="mx-0">
        <v-col cols="3">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="inicio_formated"
                label="Fecha de Inicio"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="inicio"
              @input="menu1 = false"
              locale="es-cl"
              prev-icon="chevron_left"
              next-icon="chevron_right"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="termino_formated"
                label="Fecha de termino"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="termino"
              @input="menu2 = false"
              locale="es-cl"
              prev-icon="chevron_left"
              next-icon="chevron_right"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="text-right" cols="6">
          <v-btn class="mx-2" dark color="blue darken-1" @click="loadPartes"
            ><i class="material-icons">cached</i> Generar</v-btn
          >
          <v-btn class="mx-2" dark @click="download" color="green"
            ><i class="material-icons">text_snippet</i> Excel</v-btn
          >
          <!-- <v-btn class="mx-2" dark @click="downloadPDF" color="#C62828"> <i class="material-icons">picture_as_pdf</i> PDF</v-btn> -->
        </v-col>
      </v-row>

      <spinner v-if="spinner"></spinner>

      <v-data-table
        :headers="partesListHeaders"
        :items="partesListBody"
        no-data-text="No hay partes"
        :search="search"
        fixed-header
        :height="table_height"
        :header-props="{ sortIcon: 'arrow_upward' }"
        :hide-default-footer="true"
        disable-pagination
      >
        <template v-slot:[`item.zarpe`]="{ item }">
          <span>{{ moment(item.zarpe).format("DD-MM-YYYY HH:mm") }}</span>
        </template>
        <template v-slot:[`item.recalada`]="{ item }">
          <span v-if="item.recalada">{{
            moment(item.recalada).format("DD-MM-YYYY HH:mm")
          }}</span>
        </template>
        <template v-slot:[`item.atraco_fondeo`]="{ item }">
          <span v-if="item.atraco_fondeo">{{
            moment(item.atraco_fondeo).format("DD-MM-YYYY HH:mm")
          }}</span>
        </template>
        <template v-slot:[`item.inicio_faena`]="{ item }">
          <span v-if="item.inicio_faena">{{
            moment(item.inicio_faena).format("DD-MM-YYYY HH:mm")
          }}</span>
        </template>
        <template v-slot:[`item.termino_faena`]="{ item }">
          <span v-if="item.termino_faena">{{
            moment(item.termino_faena).format("DD-MM-YYYY HH:mm")
          }}</span>
        </template>
        <template v-slot:[`item.termino_cam`]="{ item }">
          <span v-if="item.termino_cam">{{
            moment(item.termino_cam).format("DD-MM-YYYY HH:mm")
          }}</span>
        </template>
        <template v-slot:[`item.tiempo_faena`]="{ item }">
          <span v-if="item.tiempo_faena">{{
            item.tiempo_faena.substring(0, 5)
          }}</span>
        </template>
        <template v-slot:[`item.tiempo_no_operativo`]="{ item }">
          <span v-if="item.tiempo_no_operativo">{{
            item.tiempo_no_operativo.substring(0, 5)
          }}</span>
        </template>
        <template v-slot:[`item.horas`]="{ item }">
          <span v-if="item.horas">{{ item.horas.substring(0, 5) }}</span>
        </template>
        <template v-slot:[`item.millas`]="{ item }">
          {{ formatNumber(item.millas, 0) }}
        </template>
        <template v-slot:[`item.nudos`]="{ item }">
          {{ formatNumber(item.nudos, 0) }}
        </template>
        <template slot="body.append">
          <tr class="primary--text">
            <th class="title text-left" colspan="13">Totales</th>
            <th class="title text-right">{{ sumaTiempoFaena }}</th>
            <!-- <th class="title text-right">{{ sumaTiempoNoOperativo }}</th> -->
            <th class="title text-right">{{ totalNoOperativo }}</th>
            <th class="title text-right">{{ formatNumber(sumaMillas, 0) }}</th>
            <!-- <th class="title text-right">{{ sumaHoras }}</th> -->
            <th class="title text-right">{{ totalHorasNoNavegadas }}</th>
            <th class="title text-right">
              {{ formatNumber(promedioNudos, 0) }}
            </th>
            <th></th>
          </tr>
        </template>
      </v-data-table>
    </div>

    <v-snackbar v-model="snackbar" :timeout="5000" :color="color">
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
.table-wellboat {
  font-size: 16pt;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}
.card {
  margin-top: 0;
}
.card .card-header-rose .card-icon {
  background: #263238;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(32, 30, 31, 0.822);
}
</style>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    spinner:false,
    color: "green",
    snackbar: false,
    validation_message: "",
    search: null,
    isLoading: false,
    empresas: [],
    inicio: "",
    termino: "",
    menu1: false,
    menu2: false,
    url_excel: "",
    url_pdf: "",
    table_height: 600,
    partesListHeaders: [
      {
        text: "Wellboat",
        align: "center",
        sortable: true,
        value: "wellboat.nombre",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Interno/ A C Cultivo / A C Acopio",
        align: "center",
        sortable: true,
        value: "registro.nombre",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Empresa",
        align: "center",
        sortable: true,
        value: "empresa.razon_social",
        width: "200px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Punto Origen",
        align: "center",
        sortable: true,
        width: "150px",
        value: "tipo_origen.nombre",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Origen",
        align: "center",
        sortable: true,
        width: "150px",
        value: "origen.nombre",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Zarpe",
        align: "center",
        sortable: true,
        value: "zarpe",
        dataType: "Date",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Recalada",
        align: "center",
        sortable: true,
        value: "recalada",
        dataType: "Date",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Atraco / Fondeo",
        align: "center",
        sortable: true,
        value: "atraco_fondeo",
        dataType: "Date",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Destino",
        align: "center",
        sortable: true,
        value: "destino.nombre",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Folio",
        align: "center",
        sortable: true,
        value: "folio",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Inicio Faena",
        align: "center",
        sortable: true,
        value: "inicio_faena",
        dataType: "Date",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Termino Faena",
        align: "center",
        sortable: true,
        value: "termino_faena",
        dataType: "Date",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Termino CAM",
        align: "center",
        sortable: true,
        value: "termino_cam",
        dataType: "Date",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo Faena",
        align: "center",
        sortable: true,
        value: "tiempo_faena",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo No Operativo",
        align: "center",
        sortable: true,
        value: "tiempo_no_operativo",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Distancia (mn)",
        align: "center",
        sortable: true,
        value: "millas",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Horas Navegadas",
        align: "center",
        sortable: true,
        value: "horas",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Velocidad",
        align: "center",
        sortable: true,
        value: "nudos",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Observaciones",
        align: "center",
        sortable: true,
        value: "observaciones",
        class: "blue-grey darken-4 white--text",
      },
    ],
    partesListBody: [],
    total_no_operativo:null,
    total_horas_navegadas:null
  }),
  mounted() {
    this.inicio = this.moment().format("YYYY-MM-01");
    this.termino = this.moment().format("YYYY-MM-DD");
    this.loadEmpresas();
    //this.loadPartes();
    if (window.innerHeight < 768) {
      this.table_height = 450;
    }
  },
  methods: {
    promedio(key) {
      let prom = 0;
      let suma = this.partesListBody.reduce(
        (a, b) => parseFloat(a) + (parseFloat(b[key]) || 0),
        0
      );
      let total = this.partesListBody.length;
      if (total == 0) return 0;
      prom = suma / total;
      return parseInt(prom);
    },
    sumaNumeros(key) {
      return this.partesListBody.reduce(
        (a, b) => parseInt(a) + (parseInt(b[key]) || 0),
        0
      );
    },
    sumaTiempo(key) {
      let unix = this.moment("1970-01-01 00:00:00");
      let hora = unix.clone();
      this.partesListBody.forEach((p) => {
        if (p[key] != null && p[key] != "00:00:00") {
          let arrHora = p[key].split(":");
          let horas = parseInt(arrHora[0]);
          let minutos = parseInt(arrHora[1]);
          hora.add(horas, "hours");
          hora.add(minutos, "minutes");
        }
      });
      if (hora == unix) return "00:00";
      if (hora.format("DD") == "01") {
        return hora.format("HH:mm");
      }
      // horas + (días - 1) * 24
      let horas =
        parseInt(hora.format("H")) + (parseInt(hora.format("D")) - 1) * 24;
      return `${horas}:${hora.format("mm")}`;
    },
    async loadEmpresas() {
      let url = `${this.base_url}empresas`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.empresas = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadPartes() {
      this.spinner = true;
      this.partesListBody = [];
      let url = `${this.base_url}partes/porfecha/${this.user.wellboat.id}/${this.inicio}/${this.termino}`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.partesListBody = response.data.body;
          this.url_excel = response.data.url_excel;
          this.total_no_operativo = response.data.total_tiempo_no_operativo;
          this.total_horas_navegadas = response.data.total_tiempo_horas_navegadas;
          //this.url_excel = this.storage_url + response.data.url_excel;
          this.url_pdf = this.storage_url + response.data.url_pdf;
          if (this.partesListBody.length > 0) this.showSnackBar(false);
        })
        .catch((error) => {
          console.log(error);
        });
        this.spinner = false;
    },
    download() {
      window.open(this.url_excel);
    },
    downloadPDF() {
      window.open(this.url_pdf);
    },
    showSnackBar(error) {
      if (error) {
        this.color = "red";
      } else {
        this.color = "green";
        this.validation_message = "Ejecutado exitosamente!";
      }
      this.snackbar = true;
    },
    ...mapMutations(["actualizarBaseUrl", "mostrarLoading", "ocultarLoading"]),
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatNumber(numero, decimales = 2) {
      //, checknegative = false){
      //if(checknegative && numero < 0) return 'Número Inválido';
      let arrNum = numero.toString().split(".");
      if (arrNum[1] == undefined) arrNum[1] = "";
      let num = arrNum[0];
      if (!isNaN(num)) {
        num = num
          .split("")
          .reverse()
          .join("")
          .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
        num = num
          .split("")
          .reverse()
          .join("")
          .replace(/^[.]/, "");
        if (decimales > 0) num += ",";
        return num + arrNum[1].padEnd(decimales, "0");
      } else {
        return "0";
      }
    },
  },
  computed: {
    ...mapState(["storage_url", "loading", "base_url", "headers", "user"]),
    inicio_formated() {
      return this.formatDate(this.inicio);
    },
    termino_formated() {
      return this.formatDate(this.termino);
    },
    sumaTiempoFaena() {
      return this.sumaTiempo("tiempo_faena");
    },
    sumaTiempoNoOperativo() {
      return this.sumaTiempo("tiempo_no_operativo");
    },
    sumaMillas() {
      return this.sumaNumeros("millas");
    },
    sumaHoras() {
      return this.sumaTiempo("horas");
    },
    promedioNudos() {
      return this.promedio("nudos");
    },
    totalNoOperativo(){
      return this.total_no_operativo ? this.total_no_operativo.substring(0,this.total_no_operativo.length-3) : null
    },
    totalHorasNoNavegadas(){
      return this.total_horas_navegadas? this.total_horas_navegadas.substring(0,this.total_horas_navegadas.length-3):null
    }
  },
  components: {},
};
</script>
